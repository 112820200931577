var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "WEBSITES_FORM" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            scrollable: "",
          },
          on: { hidden: _vm.hidden, ok: _vm.ok },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _vm.canEdit()
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t(`customer.field.websites`))),
              ]),
              _vm._l(_vm.websites, function (website, index) {
                return _c(
                  "b-row",
                  { key: index },
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "" } },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    id: `website${index}`,
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.websites[index],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.websites, index, $$v)
                                    },
                                    expression: "websites[index]",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          id: `WEBSITE_BTN_ADD_${index}`,
                                          variant: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fieldItemAdd(index, null)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          boundary: "window",
                                          target: `WEBSITE_BTN_ADD_${index}`,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.add")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          id: `WEBSITE_BTN_REMOVE_${index}`,
                                          variant: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fieldItemRemove(
                                              index,
                                              null
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "trash-can"] },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          boundary: "window",
                                          target: `WEBSITE_BTN_REMOVE_${index}`,
                                          triggers: "hover",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.remove")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }